<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, }">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6 class="font-semibold m-0">配置中心</h6>
        </a-col>
      </a-row>
    </template>
    <a-row :gutter="[24, 24]">
      <a-col :span="24" :md="16" :lg="{ span: 16, offset: 0 }" :xl="{ span: 6, offset: 2 }" class="col-form">
        <a-form :form="form" class="login-form" @submit="handleSubmit" :hideRequiredMark="true">
          <a-form-item class="mb-5" :label="`汇总时间` + (index + 1)" :colon="false" v-for="(item, index) in inputs" :key="index">
            <a-time-picker v-model="item.value" style="width: 100%;" placeholder="请选择时间" valueFormat="HH:mm:ss" />
            <!-- <a-time-picker style="width: 100%;" placeholder="请选择时间" valueFormat="HH:mm:ss"
              v-decorator="['starttime_'+item, { rules: [{ required: true, message: '请选择时间' }] }]" /> -->
            <a-button type="danger" icon="delete" @click="timeDel(index)"></a-button>
          </a-form-item>
          <a-button type="primary" @click="inputs.push({ value: '' })">
            添加时间
          </a-button>
          <!-- <a-form-item class="mb-5" label="汇总结束时间" :colon="false">
            <a-time-picker style="width: 100%;" placeholder="请选择结束时间"
            valueFormat="HH:mm:ss"
              v-decorator="['endtime', { rules: [{ required: true, message: '请选择结束时间' }] }]" />
          </a-form-item> -->
          <!-- <a-form-item class="mb-5" label="汇总间隔" :colon="false">
            <a-input type="number" placeholder="请输入汇总间隔时间，单位h" v-decorator="[
              'interval',
              { rules: [{ required: true, message: '请输入汇总间隔时间' }] },
            ]" />
          </a-form-item> -->
          <!-- <a-form-item class="mb-5" label="AI提示词" :colon="false">
            <a-input placeholder="请输入AI汇总提示词" v-decorator="[
              'cue',
              { rules: [{ required: true, message: '请输入AI汇总提示词' }] },
            ]" />
          </a-form-item> -->
          <!-- <a-form-item class="mb-5" label="OpenAI ApiKey" :colon="false">
            <a-input placeholder="请输入OpenAI ApiKey" v-decorator="[
              'apikey',
              { rules: [{ required: true, message: '请输入OpenAI ApiKey' }] },
            ]" />
          </a-form-item> -->
          <a-form-item style="margin-top: 20px;">
            <a-button type="primary" block html-type="submit" class="login-form-button">
              保存
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>

const key = 'add';
import { updateSetting, getSetting } from "@/api/service.js";

export default ({
  components: {
  },
  data() {
    return {
      inputs: [],
      form: this.$form.createForm(this),
    }
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      getSetting().then(res => {
        let times = res.data.data[0].time.split(',');
        times.forEach(item=>{
          this.inputs.push({
            value:item
          })
        })
      }).catch(err => {
      })
    },
    timeDel(index) {
      this.inputs.splice(index, 1);
    },
    handleSubmit() {
      this.$message.loading({ content: '正在保存...', key });
      this.form.validateFields((err, values) => {
        if (!err) {
          let time = []
          this.inputs.forEach(item => {
            if (item.value) {
              time.push(item.value);
            }
          })
          time = time.toString();
          updateSetting({ time }).then(res => {
            this.$message.success({ content: '保存成功', key, duration: 2 });
          }).catch(err => {
            this.$message.error({ content: '保存失败！' + err, key, duration: 2 });
          })
        }
      });
    },
    add() {
      this.visible.add = true;
      this.$nextTick(() => {
        if (this.formRef) {
          this.formRef.resetFields();
        }
      });
    },
    del(key) {
      this.$confirm({
        title: '你确认要删除此板块?',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          console.log('OK');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }
})

</script>

<style lang="scss" scoped>
::v-deep .ant-form-item-children {
  display: flex;

  button {
    margin-left: 10px;
  }
}
</style>